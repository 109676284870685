import { getAccessToken, setAccessToken } from 'src/util/local_storage'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useUserState } from 'src/ducks/selectors'
import { publicAxios } from 'src/config/axios'
import { userSlice } from 'src/ducks/slices/user'
import { navigate } from 'gatsby'
import { isLoggedIn, moveTop } from 'src/util/common'
import { useBreakpoint } from 'src/util/breakpoints'
import { CirclingDot } from 'src/components/atoms/loading'

// const IGNORE_LOGIN_PATH_LIST = ['/', '/login']

const Authentication = ({ props, element }: any) => {
  const { isSm } = useBreakpoint('sm')
  const [isLoading, setIsLoading] = useState(true)
  const [isRequireLogin, setIsRequireLogin] = useState(false)
  const dispatch = useDispatch()
  const userState = useUserState().user
  const loggedIn = isLoggedIn(userState?.user)

  useEffect(() => {
    setIsLoading(true)
    setIsRequireLogin(false)
    authenticate()
  }, [props.location.pathname])

  const loadingDisplay = <CirclingDot isSm={isSm} />

  const authenticate = async () => {
    if (loggedIn) {
      setIsRequireLogin(false)
      setIsLoading(false)
      if (!userState.user.welcomeFlag) {
        if (props.location.pathname.includes('welcome')) {
          return
        }
        navigate('/welcome')
        return
      }
      if (props.location.pathname.includes('login')) {
        moveTop()
        return
      }
    } else {
      const user = await accessTokenLogin()
      setIsRequireLogin(checkRequireLogin(user))
      if (!!user && !user?.welcomeFlag) {
        if (!props.location.pathname.includes('welcome')) {
          navigate('/welcome')
          return
        }
      }
      setIsLoading(false)
    }
  }

  const accessTokenLogin = async () => {
    const accessToken = getAccessToken()
    if (accessToken) {
      const payload = {
        access_token: accessToken,
      }
      const response = await publicAxios.post('/authorizations/auth_access_token', payload)
      return response.data
    } else {
      return null
    }
  }

  const checkRequireLogin = (user) => {
    // if (!IGNORE_LOGIN_PATH_LIST.includes(props.location.pathname) && !user) {
    //   const encoded = encodeURI(props.location.pathname)
    //   navigate(`/login?referrer=${encoded}`)
    //   return true
    // }
    if (user) {
      dispatch(userSlice.actions.setUser(user))
      setAccessToken(user.accessToken)
      return false
    }
  }

  if (isLoading) {
    return <>{loadingDisplay}</>
  }

  return <>{isRequireLogin ? <>{loadingDisplay}</> : <>{element}</>}</>
}

export default Authentication
