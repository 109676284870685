import * as React from 'react'
import type { GatsbyBrowser } from 'gatsby'
import 'tailwindcss/tailwind.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Provider } from 'react-redux'
import configureStore from './src/ducks/stores'
import './src/assets/styles/globals.scss'
import Authentication from './src/components/templates/authentication'
import { PersistGate } from 'redux-persist/integration/react'
import * as Sentry from '@sentry/gatsby'
import ErrorBoundary from 'src/components/organisms/error_boundary'
import Footer from 'src/components/organisms/footer'

Sentry.init({
  dsn: 'https://8351347af75b2b3aab17387d419d34cb@o4507533201965056.ingest.us.sentry.io/4507533353811968',
  environment: process.env.NODE_ENV || 'development',
  tracesSampleRate: 1.0,
  debug: false,
  enabled: (['production', 'staging'].includes(process.env.NODE_ENV) || process.env.SENTRY_ENABLED || false) as boolean,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
})

const { persistor, store } = configureStore()

const NO_FOOTER_PATH_LIST = ['/']

export const wrapPageElement = ({ element, props }) => (
  <>
    <Authentication props={props} element={element} />
    {!NO_FOOTER_PATH_LIST.includes(props.path) && <Footer />}
  </>
)

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  </ErrorBoundary>
)

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => false
