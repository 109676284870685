import React from 'react'
import { Box, Link, Stack } from '@mui/material'
import { useBreakpoint } from 'src/util/breakpoints'
import Copyright from 'src/components/organisms/copyright'

export const Footer = ({ withNavigation = false }: { withNavigation?: boolean }) => {
  const { isSm } = useBreakpoint('sm')

  const styles = {
    footer: {
      width: '100%',
      backgroundColor: '#f9f4de',
    },
    contents: {
      padding: isSm ? '14px 30px' : '28px 100px',
    },
    subtitle: {
      fontWeight: 'bold',
      paddingBottom: isSm ? '2px' : '4px',
      borderBottom: '1px solid #9ba0a4',
      fontSize: isSm ? '13.4px' : '16px',
    },
    linkWrapper: {
      paddingTop: isSm ? '6px' : '8px',
      lineHeight: isSm ? '30px' : '34px',
      fontSize: isSm ? '11.5px' : '14px',
    },
    link: {
      '&:hover': {
        color: '#1A73E8',
        cursor: 'pointer',
      },
    },
    dummy: { height: isSm ? '54px' : '70px' },
  }
  return (
    <>
      <Box sx={styles.footer}>
        <Stack direction="row" justifyContent="start" alignItems="center" spacing={2}>
          <Box sx={styles.contents}>
            <Box sx={styles.subtitle}>
              <Box>ジョイナについて</Box>
            </Box>
            <Box sx={styles.linkWrapper}>
              <div>
                <Link href="/guide" underline="none" color="inherit" sx={styles.link}>
                  はじめての方へ
                </Link>
              </div>
              <div>
                <Link href="/lp" underline="none" color="inherit" sx={styles.link}>
                  漫画でわかるジョイナ！
                </Link>
              </div>
              <div>
                <Link href="https://company.joinna.net/" underline="none" color="inherit" sx={styles.link}>
                  会社概要
                </Link>
              </div>
              {/* <div>
                <Link href="#" underline="none" color="inherit" sx={styles.link}>
                  プライバシーポリシー
                </Link>
              </div> */}
              <div>
                <Link href="/legal/external_tools" underline="none" color="inherit" sx={styles.link}>
                  外部送信ツールについて
                </Link>
              </div>
              <div>
                <Link href="/legal/specified_commercial_transactions" underline="none" color="inherit" sx={styles.link}>
                  特定商取引法に基づく表記
                </Link>
              </div>
              <div>
                <Link href="/inquiry" underline="none" color="inherit" sx={styles.link}>
                  お問い合わせ
                </Link>
              </div>
            </Box>
          </Box>
        </Stack>
      </Box>
      <Copyright />
      {withNavigation && <Box sx={styles.dummy} />}
    </>
  )
}

export default Footer
